
import dynamic from 'next/dynamic'
import Image from "next/legacy/image";
import NextImage from "next/image";
import Link from 'next/link';
import { useEffect, useState } from 'react';
import BookingInfoForm from '@/components/Booking/Form';
const Layout = dynamic(() => import('@components/Layout'))
const EmblaCarousel = dynamic(() => import('@components/Carousel/EmblaCarousel'), {
    loading: () => 'Loading...',
});
const PartnerPage = dynamic(() => import('@components/PartnerPage'), {
    loading: () => 'Loading...',
});
const slides = [
    {
        "id": 1,
        "title": "Experience the Rapidé Way!",
        "description": "CASA-quality services at affordable prices.",
        "button_name": "Book your car now",
        "link": "",
        "image_url": "/assets/slides/size/jpg/1.jpg",
        "mobile_image_url": "/assets/slides/size/jpg/1.jpg",
        "pos": 2,
        "text_color": 1,
        "mobile_text_color": 0,
        "created_at": "2021-09-09 14:20:01",
        "updated_at": "2021-11-04 13:54:08"
    },
    {
        "id": 2,
        "title": "Oil Change",
        "description": "Get the fastest Oil Change job done right before your eyes! Rapidé Express Oil Change is comprehensive preventive maintenance to check, inspect, flush, fill and clean essential systems and components of your vehicle.",
        "button_name": "Our Services",
        "link": "",
        "image_url": "/assets/slides/size/jpg/2.jpg",
        "mobile_image_url": "/assets/slides/size/jpg/2.jpg",
        "pos": 4,
        "text_color": 1,
        "mobile_text_color": 0,
        "created_at": "2021-09-09 10:54:42",
        "updated_at": "2021-11-04 15:26:03"
    },
    {
        "id": 3,
        "title": "Brakes",
        "description": "The foremost area of safety, Rapidé offers brake pads, shoes and other brakes services.",
        "button_name": "",
        "link": "",
        "image_url": "/assets/slides/size/jpg/3.jpg",
        "mobile_image_url": "/assets/slides/size/jpg/3.jpg",
        "pos": 4,
        "text_color": 1,
        "mobile_text_color": 0,
        "created_at": "2021-09-09 10:54:42",
        "updated_at": "2021-11-04 15:26:03"
    },
    {
        "id": 4,
        "title": "Maintenance",
        "description": "General maintenance services to ensure that your car runs as it should!",
        "button_name": "",
        "link": "",
        "image_url": "/assets/slides/size/jpg/4.jpg",
        "mobile_image_url": "/assets/slides/size/jpg/4.jpg",
        "pos": 4,
        "text_color": 1,
        "mobile_text_color": 0,
        "created_at": "2021-09-09 10:54:42",
        "updated_at": "2021-11-04 15:26:03"
    },
    {
        "id": 5,
        "title": "Tires & Batteries",
        "description": "Your vehicle’s body and soul. We'll make sure you'll get the best tires and batteries fit for your vehicle brand..",
        "button_name": "",
        "link": "",
        "image_url": "/assets/slides/size/jpg/5.jpg",
        "mobile_image_url": "/assets/slides/size/jpg/5.jpg",
        "pos": 4,
        "text_color": 1,
        "mobile_text_color": 0,
        "created_at": "2021-09-09 10:54:42",
        "updated_at": "2021-11-04 15:26:03"
    }
];

const slides2 = [
    {
        "id": 1,
        "title": "Test1 title",
        "description": "CASA-quality services at affordable prices.",
        "button_name": "Book your car now",
        "link": "",
        "image_url": "/assets/slides/size/jpg/1.jpg",
        "mobile_image_url": "/assets/slides/size/jpg/1.jpg",
        "pos": 2,
        "text_color": 1,
        "mobile_text_color": 0,
        "created_at": "2021-09-09 14:20:01",
        "updated_at": "2021-11-04 13:54:08"
    },
    {
        "id": 2,
        "title": "Test2",
        "description": "CASA-quality services at affordable prices.",
        "button_name": "Book your car now",
        "link": "",
        "image_url": "/assets/slides/size/jpg/2.jpg",
        "mobile_image_url": "/assets/slides/size/jpg/2.jpg",
        "pos": 1,
        "text_color": 1,
        "mobile_text_color": 0,
        "created_at": "2021-09-09 14:20:01",
        "updated_at": "2021-11-04 13:54:08"
    },
];

export default function Home({ device, carousels}) {
    const [slides, setSlides] = useState(null);
    const [slides2, setSlides2] = useState(null);
    useEffect(()=>{
        if(carousels.length <= 0) return;
        let s1 = [];
        let s2 = [];
        carousels.forEach(element => {
            if(element.type_id == 1){
                s1.push(element);
            }
            if(element.type_id == 2){
                s2.push(element);
            }
        });
        setSlides(s1);
        setSlides2(s2);
    },[carousels]);
    return (
        <Layout pageTitle='Rapidé - Homepage'>
            <div className="relative top-0 left-0 md:py-10 max-w-full mx-auto bg-fixed bg-[url('/assets/slides/size/jpg/5.jpg')] bg-cover bg-no-repeat">
                {/*<div className="block absolute top-0 left-0 w-full h-full bg-white/60"></div>*/}
                <div className="max-w-screen-xl mx-auto flex md:flex-row flex-col md:pt-[110px] pt-[115px] md:px-4 sm:px-2 px-1 sm:pb-4 pb-2">
                    <div className='md:basis-1/2 relative p-2 flex flex-col justify-center align-middle'>
                        <div className='relative text-left'>
                            <p className='text-white font-bold md:text-4xl text-2xl'>Experience the Rapidé Way!</p>
                            <p className='text-white md:text-lg text-base md:mt-4 mt-2'>CASA-quality services at affordable prices.</p>
                        </div>
                    </div>
                    <div className='md:basis-1/2 basis-full z-20'>
                        <div className='bg-white/80 md:px-4 md:py-4 px-2 py-2 rounded-md flex flex-col justify-center'>
                            <div className="relative flex flex-col mb-2">
                                <label className="font-bold tracking-tight text-gray-900">Get FREE Estimate for your Car Service</label>
                            </div>
                            <BookingInfoForm className={`w-full `}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative top-0 max-w-full mx-auto border-t-2 border-rapide-yellow">
                <EmblaCarousel classN={'lg:h-[750px] md:h-[550px] h-[370px]'} nkey={'-first'} autoplayInterval={7000} slides={slides} mobile={device} />
            </div>
            <main className="relative overflow-hidden max-h-full px-4">
                <div className={`max-w-full mx-auto mt-2 md:pt-[100px] pt-[0] pb-[100px] bg-no-repeat bg-fixed md:bg-[left_0_top_15rem] bg-[left_0rem_top_4rem] bg-[url('/assets/images/homepage/home-about-bg2-500.webp')]`}>
                    <div className='relative sm:inset-x-1/3 left-0 sm:w-4/6 w-full sm:p-24 p-12 bg-rapide-yellow'>
                        <h1 className='text-4xl font-bold'>About RAPIDÉ</h1>
                        <p className='pt-2 font-light'>Our team of skilled technicians, coupled with state of the art equipment, allow us to fulfill this vision. This vision is what we now refer to as the Rapidé Way, and it&rsquo;s something that separates us from every other competitor out there. Curious about the #RapidéWay and what makes it so good? Come and experience it for yourself!</p>
                        <Link className='text-sm font-semibold tracking-widest absolute -bottom-4 right-2 px-6 py-2 bg-rapide-black text-rapide-yellow hover:text-white uppercase' href="/about-us">
                            Learn More About Rapidé
                        </Link>
                    </div>
                </div>
                <div className='max-w-full mx-auto mt-8 grid md:grid-cols-2 grid-cols-1 gap-4 pb-12'>
                    <div className='relative h-full'>
                        <div className='relative flex w-full h-full'>
                            <NextImage
                                className='w-full'
                                alt='testimonial'
                                src={'/assets/images/homepage/testimonial_800.webp'}
                                layout="responsive"
                                width={1000}
                                height={800}
                            />
                        </div>
                    </div>
                    <div className=''>
                        <EmblaCarousel classN={`md:h-[100%] sm:h-[440px] h-[440px]`} autoplayInterval={9000} nkey={'-second'} slides={slides2} mobile={device} />
                    </div>
                </div>
            </main>
            <PartnerPage/>
        </Layout>
    )
}

export async function getServerSideProps(context) {
    //const ua = context.req.headers["user-agent"].toLowerCase();
    let isMobile = 0;
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(ua.substr(0,4))) {
    //     isMobile = 1;
    // }

    let carousels = [];
    try {
        const getCarousels = await fetch(`${process.env.API_URL}/carousels`);
        carousels = await getCarousels.json();
    } catch (error) {
        console.log(error);
    }
    return {
        props: {
            device: isMobile,
            carousels,
        },
    }
}